export const links = [
  {
    id: 1,
    url: "/",
    text: "about",
  },
  // {
  //   id: 2,
  //   url: '/projects',
  //   text: 'projects',
  // },
  {
    id: 2,
    url: "/contact",
    text: "contact",
  },
];
